import React from 'react';

const Project = ({ pageContext }) => {
  console.log(pageContext);

  return <div>
    <h1>Project</h1>
    <p>{JSON.stringify(pageContext)}</p>
  </div>;
}

export default Project;
